
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//audits routes
			{
				path: '/audits/:fieldName?/:fieldValue?',
				name: 'auditslist',
				component: () => import('./pages/audits/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/audits/view/:id', 
				name: 'auditsview', 
				component: () => import('./pages/audits/view.vue'), 
				props: true
			},
		

//cargos routes
			{
				path: '/cargos/:fieldName?/:fieldValue?',
				name: 'cargoslist',
				component: () => import('./pages/cargos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cargos/view/:id', 
				name: 'cargosview', 
				component: () => import('./pages/cargos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cargos/add', 
				name: 'cargosadd', 
				component: () => import('./pages/cargos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cargos/edit/:id', 
				name: 'cargosedit', 
				component: () => import('./pages/cargos/edit.vue'), 
				props: true
			},
		

//cliente routes
			{
				path: '/cliente/:fieldName?/:fieldValue?',
				name: 'clientelist',
				component: () => import('./pages/cliente/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cliente/view/:id', 
				name: 'clienteview', 
				component: () => import('./pages/cliente/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cliente/add', 
				name: 'clienteadd', 
				component: () => import('./pages/cliente/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cliente/edit/:id', 
				name: 'clienteedit', 
				component: () => import('./pages/cliente/edit.vue'), 
				props: true
			},
		

//clientecontactos routes
			{
				path: '/clientecontactos/:fieldName?/:fieldValue?',
				name: 'clientecontactoslist',
				component: () => import('./pages/clientecontactos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientecontactos/view/:id', 
				name: 'clientecontactosview', 
				component: () => import('./pages/clientecontactos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientecontactos/add', 
				name: 'clientecontactosadd', 
				component: () => import('./pages/clientecontactos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientecontactos/edit/:id', 
				name: 'clientecontactosedit', 
				component: () => import('./pages/clientecontactos/edit.vue'), 
				props: true
			},
		

//clientehasdocumentos routes
			{
				path: '/clientehasdocumentos/:fieldName?/:fieldValue?',
				name: 'clientehasdocumentoslist',
				component: () => import('./pages/clientehasdocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientehasdocumentos/view/:id', 
				name: 'clientehasdocumentosview', 
				component: () => import('./pages/clientehasdocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientehasdocumentos/add', 
				name: 'clientehasdocumentosadd', 
				component: () => import('./pages/clientehasdocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientehasdocumentos/edit/:id', 
				name: 'clientehasdocumentosedit', 
				component: () => import('./pages/clientehasdocumentos/edit.vue'), 
				props: true
			},
		

//cotizacionestados routes
			{
				path: '/cotizacionestados/:fieldName?/:fieldValue?',
				name: 'cotizacionestadoslist',
				component: () => import('./pages/cotizacionestados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionestados/view/:id', 
				name: 'cotizacionestadosview', 
				component: () => import('./pages/cotizacionestados/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionestados/add', 
				name: 'cotizacionestadosadd', 
				component: () => import('./pages/cotizacionestados/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizacionestados/edit/:id', 
				name: 'cotizacionestadosedit', 
				component: () => import('./pages/cotizacionestados/edit.vue'), 
				props: true
			},
		

//geociudades routes
			{
				path: '/geociudades/:fieldName?/:fieldValue?',
				name: 'geociudadeslist',
				component: () => import('./pages/geociudades/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geociudades/view/:id', 
				name: 'geociudadesview', 
				component: () => import('./pages/geociudades/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geociudades/add', 
				name: 'geociudadesadd', 
				component: () => import('./pages/geociudades/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geociudades/edit/:id', 
				name: 'geociudadesedit', 
				component: () => import('./pages/geociudades/edit.vue'), 
				props: true
			},
		

//geodepartamentos routes
			{
				path: '/geodepartamentos/:fieldName?/:fieldValue?',
				name: 'geodepartamentoslist',
				component: () => import('./pages/geodepartamentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geodepartamentos/view/:id', 
				name: 'geodepartamentosview', 
				component: () => import('./pages/geodepartamentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geodepartamentos/add', 
				name: 'geodepartamentosadd', 
				component: () => import('./pages/geodepartamentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geodepartamentos/edit/:id', 
				name: 'geodepartamentosedit', 
				component: () => import('./pages/geodepartamentos/edit.vue'), 
				props: true
			},
		

//geopaises routes
			{
				path: '/geopaises/:fieldName?/:fieldValue?',
				name: 'geopaiseslist',
				component: () => import('./pages/geopaises/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geopaises/view/:id', 
				name: 'geopaisesview', 
				component: () => import('./pages/geopaises/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geopaises/add', 
				name: 'geopaisesadd', 
				component: () => import('./pages/geopaises/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geopaises/edit/:id', 
				name: 'geopaisesedit', 
				component: () => import('./pages/geopaises/edit.vue'), 
				props: true
			},
		

//metodospago routes
			{
				path: '/metodospago/:fieldName?/:fieldValue?',
				name: 'metodospagolist',
				component: () => import('./pages/metodospago/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/metodospago/view/:id', 
				name: 'metodospagoview', 
				component: () => import('./pages/metodospago/view.vue'), 
				props: true
			},
		
			{ 
				path: '/metodospago/add', 
				name: 'metodospagoadd', 
				component: () => import('./pages/metodospago/add.vue'), 
				props: true
			},
	
			{ 
				path: '/metodospago/edit/:id', 
				name: 'metodospagoedit', 
				component: () => import('./pages/metodospago/edit.vue'), 
				props: true
			},
		

//permissions routes

//permissionslist routes

//procesosinternos routes
			{
				path: '/procesosinternos/:fieldName?/:fieldValue?',
				name: 'procesosinternoslist',
				component: () => import('./pages/procesosinternos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/procesosinternos/view/:id', 
				name: 'procesosinternosview', 
				component: () => import('./pages/procesosinternos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/procesosinternos/add', 
				name: 'procesosinternosadd', 
				component: () => import('./pages/procesosinternos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/procesosinternos/edit/:id', 
				name: 'procesosinternosedit', 
				component: () => import('./pages/procesosinternos/edit.vue'), 
				props: true
			},
		

//proyectohasdocumentos routes
			{
				path: '/proyectohasdocumentos/:fieldName?/:fieldValue?',
				name: 'proyectohasdocumentoslist',
				component: () => import('./pages/proyectohasdocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectohasdocumentos/view/:id', 
				name: 'proyectohasdocumentosview', 
				component: () => import('./pages/proyectohasdocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectohasdocumentos/add', 
				name: 'proyectohasdocumentosadd', 
				component: () => import('./pages/proyectohasdocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectohasdocumentos/edit/:id', 
				name: 'proyectohasdocumentosedit', 
				component: () => import('./pages/proyectohasdocumentos/edit.vue'), 
				props: true
			},
		

//proyectohasresponsables routes
			{
				path: '/proyectohasresponsables/:fieldName?/:fieldValue?',
				name: 'proyectohasresponsableslist',
				component: () => import('./pages/proyectohasresponsables/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectohasresponsables/view/:id', 
				name: 'proyectohasresponsablesview', 
				component: () => import('./pages/proyectohasresponsables/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectohasresponsables/add', 
				name: 'proyectohasresponsablesadd', 
				component: () => import('./pages/proyectohasresponsables/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectohasresponsables/edit/:id', 
				name: 'proyectohasresponsablesedit', 
				component: () => import('./pages/proyectohasresponsables/edit.vue'), 
				props: true
			},
		

//proyectoimagenes routes
			{
				path: '/proyectoimagenes/:fieldName?/:fieldValue?',
				name: 'proyectoimageneslist',
				component: () => import('./pages/proyectoimagenes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectoimagenes/view/:id', 
				name: 'proyectoimagenesview', 
				component: () => import('./pages/proyectoimagenes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectoimagenes/add', 
				name: 'proyectoimagenesadd', 
				component: () => import('./pages/proyectoimagenes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectoimagenes/edit/:id', 
				name: 'proyectoimagenesedit', 
				component: () => import('./pages/proyectoimagenes/edit.vue'), 
				props: true
			},
		

//proyectos routes
			{
				path: '/proyectos/:fieldName?/:fieldValue?',
				name: 'proyectoslist',
				component: () => import('./pages/proyectos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectos/view/:id', 
				name: 'proyectosview', 
				component: () => import('./pages/proyectos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectos/add', 
				name: 'proyectosadd', 
				component: () => import('./pages/proyectos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectos/edit/:id', 
				name: 'proyectosedit', 
				component: () => import('./pages/proyectos/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//segmentos routes
			{
				path: '/segmentos/:fieldName?/:fieldValue?',
				name: 'segmentoslist',
				component: () => import('./pages/segmentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/segmentos/view/:id', 
				name: 'segmentosview', 
				component: () => import('./pages/segmentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/segmentos/add', 
				name: 'segmentosadd', 
				component: () => import('./pages/segmentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/segmentos/edit/:id', 
				name: 'segmentosedit', 
				component: () => import('./pages/segmentos/edit.vue'), 
				props: true
			},
		

//tipodocumentos routes
			{
				path: '/tipodocumentos/:fieldName?/:fieldValue?',
				name: 'tipodocumentoslist',
				component: () => import('./pages/tipodocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipodocumentos/view/:id', 
				name: 'tipodocumentosview', 
				component: () => import('./pages/tipodocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipodocumentos/add', 
				name: 'tipodocumentosadd', 
				component: () => import('./pages/tipodocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipodocumentos/edit/:id', 
				name: 'tipodocumentosedit', 
				component: () => import('./pages/tipodocumentos/edit.vue'), 
				props: true
			},
		

//tipoidentificaciones routes
			{
				path: '/tipoidentificaciones/:fieldName?/:fieldValue?',
				name: 'tipoidentificacioneslist',
				component: () => import('./pages/tipoidentificaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoidentificaciones/view/:id', 
				name: 'tipoidentificacionesview', 
				component: () => import('./pages/tipoidentificaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoidentificaciones/add', 
				name: 'tipoidentificacionesadd', 
				component: () => import('./pages/tipoidentificaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoidentificaciones/edit/:id', 
				name: 'tipoidentificacionesedit', 
				component: () => import('./pages/tipoidentificaciones/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usersuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

//vehiculocolores routes
			{
				path: '/vehiculocolores/:fieldName?/:fieldValue?',
				name: 'vehiculocoloreslist',
				component: () => import('./pages/vehiculocolores/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculocolores/view/:id', 
				name: 'vehiculocoloresview', 
				component: () => import('./pages/vehiculocolores/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vehiculocolores/add', 
				name: 'vehiculocoloresadd', 
				component: () => import('./pages/vehiculocolores/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculocolores/edit/:id', 
				name: 'vehiculocoloresedit', 
				component: () => import('./pages/vehiculocolores/edit.vue'), 
				props: true
			},
		

//vehiculolinea routes
			{
				path: '/vehiculolinea/:fieldName?/:fieldValue?',
				name: 'vehiculolinealist',
				component: () => import('./pages/vehiculolinea/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculolinea/view/:id', 
				name: 'vehiculolineaview', 
				component: () => import('./pages/vehiculolinea/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vehiculolinea/add', 
				name: 'vehiculolineaadd', 
				component: () => import('./pages/vehiculolinea/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculolinea/edit/:id', 
				name: 'vehiculolineaedit', 
				component: () => import('./pages/vehiculolinea/edit.vue'), 
				props: true
			},
		

//vehiculomarcas routes
			{
				path: '/vehiculomarcas/:fieldName?/:fieldValue?',
				name: 'vehiculomarcaslist',
				component: () => import('./pages/vehiculomarcas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculomarcas/view/:id', 
				name: 'vehiculomarcasview', 
				component: () => import('./pages/vehiculomarcas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vehiculomarcas/add', 
				name: 'vehiculomarcasadd', 
				component: () => import('./pages/vehiculomarcas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculomarcas/edit/:id', 
				name: 'vehiculomarcasedit', 
				component: () => import('./pages/vehiculomarcas/edit.vue'), 
				props: true
			},
		

//vehiculomotores routes
			{
				path: '/vehiculomotores/:fieldName?/:fieldValue?',
				name: 'vehiculomotoreslist',
				component: () => import('./pages/vehiculomotores/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculomotores/view/:id', 
				name: 'vehiculomotoresview', 
				component: () => import('./pages/vehiculomotores/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vehiculomotores/add', 
				name: 'vehiculomotoresadd', 
				component: () => import('./pages/vehiculomotores/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculomotores/edit/:id', 
				name: 'vehiculomotoresedit', 
				component: () => import('./pages/vehiculomotores/edit.vue'), 
				props: true
			},
		

//vehiculos routes
			{
				path: '/vehiculos/:fieldName?/:fieldValue?',
				name: 'vehiculoslist',
				component: () => import('./pages/vehiculos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculos/view/:id', 
				name: 'vehiculosview', 
				component: () => import('./pages/vehiculos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vehiculos/add', 
				name: 'vehiculosadd', 
				component: () => import('./pages/vehiculos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculos/edit/:id', 
				name: 'vehiculosedit', 
				component: () => import('./pages/vehiculos/edit.vue'), 
				props: true
			},
		

//vehiculosconfiguraciones routes
			{
				path: '/vehiculosconfiguraciones/:fieldName?/:fieldValue?',
				name: 'vehiculosconfiguracioneslist',
				component: () => import('./pages/vehiculosconfiguraciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculosconfiguraciones/view/:id', 
				name: 'vehiculosconfiguracionesview', 
				component: () => import('./pages/vehiculosconfiguraciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vehiculosconfiguraciones/add', 
				name: 'vehiculosconfiguracionesadd', 
				component: () => import('./pages/vehiculosconfiguraciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculosconfiguraciones/edit/:id', 
				name: 'vehiculosconfiguracionesedit', 
				component: () => import('./pages/vehiculosconfiguraciones/edit.vue'), 
				props: true
			},
		

//vehiculotipocarrocerias routes
			{
				path: '/vehiculotipocarrocerias/:fieldName?/:fieldValue?',
				name: 'vehiculotipocarroceriaslist',
				component: () => import('./pages/vehiculotipocarrocerias/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculotipocarrocerias/view/:id', 
				name: 'vehiculotipocarroceriasview', 
				component: () => import('./pages/vehiculotipocarrocerias/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vehiculotipocarrocerias/add', 
				name: 'vehiculotipocarroceriasadd', 
				component: () => import('./pages/vehiculotipocarrocerias/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculotipocarrocerias/edit/:id', 
				name: 'vehiculotipocarroceriasedit', 
				component: () => import('./pages/vehiculotipocarrocerias/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
