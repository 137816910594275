
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Vehículos config",
    "icon": "pi pi-car",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/vehiculomarcas",
        "label": "Marcas",
        "icon": "pi pi-car",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/vehiculolinea",
        "label": "Lineas",
        "icon": "pi pi-car",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/vehiculocolores",
        "label": "Colores",
        "icon": "pi pi-palette",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/vehiculomotores",
        "label": "Motores",
        "icon": "pi pi-discord",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/vehiculosconfiguraciones",
        "label": "Configuraciones",
        "icon": "pi pi-server",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/vehiculotipocarrocerias",
        "label": "Tipo carrocerias",
        "icon": "pi pi-car",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Comercial",
    "icon": "pi pi-shield",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/cliente",
        "label": "Clientes",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/vehiculos",
        "label": "Vehiculos",
        "icon": "pi pi-car",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Sistema",
    "icon": "pi pi-database",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/users",
        "label": "Usuarios",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/roles",
        "label": "Roles",
        "icon": "pi pi-key",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/audits",
        "label": "Auditoria",
        "icon": "pi pi-lock",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cargos",
        "label": "Cargos",
        "icon": "pi pi-briefcase",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Configuraciones",
    "icon": "pi pi-wrench",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/tipodocumentos",
        "label": "Tipos documentos",
        "icon": "pi pi-file-pdf",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipoidentificaciones",
        "label": "Tipos identificación",
        "icon": "pi pi-id-card",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cotizacionestados",
        "label": "Estados cotizaciones",
        "icon": "pi pi-file-edit",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geopaises",
        "label": "Paises",
        "icon": "pi pi-globe",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geodepartamentos",
        "label": "Departamentos",
        "icon": "pi pi-globe",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geociudades",
        "label": "Ciudades",
        "icon": "pi pi-globe",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/configuraciones",
    "label": "Configuraciones",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    
  }
],
	tipo: [    
{value: "Interno", label: "Interno"},
	{value: "Externo", label: "Externo"}
    ],
	estado: [    
{value: "Activo", label: "Activo"},
	{value: "Inactivo", label: "Inactivo"}
    ],
	tipo2: [    
{value: "Portada", label: "Portada"},
	{value: "Otros", label: "Otros"}
    ],
	destino: [    
{value: "Clientes", label: "Clientes"},
	{value: "Proyectos", label: "Proyectos"}
    ],
	tipoUsuario: [    
{value: "Cliente", label: "Cliente"},
	{value: "Usuario", label: "Usuario"}
    ],
	servicio: [    
{value: "Particular", label: "Particular"},
	{value: "Publico", label: "Publico"},
	{value: "Oficial", label: "Oficial"}
    ],
	tipo2: [    
{value: "Liviano", label: "Liviano"},
	{value: "Pesado", label: "Pesado"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}